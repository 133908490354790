<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="7"
      >
        <update-content-info-card
          v-if="updateContent"
          :update-content="updateContent"
          :content-max-length="contentMaxLength"
          :is-uploading-file="isUploadingFile"
          @input-img-file="inputImgFile"
        />
      </b-col>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="5"
      >
        <action-card
          :is-saving="isSaving"
          :is-valid="isValid"
          @save="create"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BRow,
  BCol,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import UpdateContentInfoCard from './components/UpdateContentInfoCard.vue'
import ActionCard from './components/ActionCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
    UpdateContentInfoCard,
    ActionCard,
  },
  setup() {
    return {
      ...viewModel(),
    }
  },
}
</script>
