<template>
  <b-overlay
    id="overlay-background"
    :show="isUploadingFile"
    variant="tranparent"
    opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-card title="업데이트 정보">
      <b-row>
        <b-col cols="4">
          <b-form-group label="IDX">
            <b-form-input
              :value="updateContent.idx"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="TYPE">
            <v-select
              v-model="updateContent.type"
              :options="typeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="VERSION">
            <b-form-input
              v-model="updateContent.version"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="TITLE">
            <b-form-input
              v-model="updateContent.title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="LINK">
            <tagby-copyable-input
              v-model="updateContent.link_url"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="`CONTENT (${contentLength}/${contentMaxLength})`">
            <b-form-textarea
              v-model="updateContent.content"
              :placeholder="`Enter only ${contentMaxLength} characters`"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="IMG">
            <b-form-file
              :placeholder="updateContent.img != null ? updateContent.img.file_name : 'Choose a file or drop it here...'"
              accept="image/*"
              @input="$emit('input-img-file', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-img
            v-if="updateContent.img"
            :src="updateContent.img.url"
            fluid
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BFormFile,
  BOverlay,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BImg,
    BFormFile,
    BOverlay,
    vSelect,
    TagbyCopyableInput,
  },
  props: {
    updateContent: {
      type: Object,
      default: () => {},
    },
    contentMaxLength: {
      type: Number,
      required: true,
    },
    isUploadingFile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const typeOptions = [
      'WEB',
      'APP',
    ]
    const contentLength = computed(() => props.updateContent.content?.length)
    return {
      typeOptions,
      contentLength,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
